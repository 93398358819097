import Vue from 'vue'
import Vuex from 'vuex'
// import ConFiador from '@/utils/arrendamiento_con_fiador.json'
// import SinFiador from '@/utils/arrendamiento_sin_fiador.json'
import { db } from '@/services/firebase'
import { collection, query, where, getDocs, orderBy } from "firebase/firestore";
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    templates: [],
    template: null,
    email: null,
    answers: {},
    processes: [],
    uid: null,

  },
  getters: {
  },
  mutations: {
    setTemplate(state, template) {
      state.template = template
    },
    setTemplates(state, templates){
      state.templates = templates
    },
    setProcesses(state, processes){
      state.processes = processes
    }
  },
  actions: {
    getTemplates: async (context) => {
      // console.log('getting templates')
      const q = query(collection(db, "ourTemplates"), where("deleted", "==", false), where("country", "==", 'GT'), orderBy("name"));
      const querySnapshot = await getDocs(q);
      var templates = []
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        var data = doc.data()
        data.id = doc.id
        data.name = data.name.split('.')[0]
        templates.push(data)
      })
      context.commit('setTemplates', templates)
    },
    getProcesses: async (context) => {
      const q = query(collection(db, "processes"), where("uid", "==", context.state.uid), where("service", '==', "arrendamientosgt"), orderBy('timestamp', 'desc'));
      const querySnapshot = await getDocs(q);
      var items = []
      const today = new Date()
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        var data = doc.data()
        data.id = doc.id
        data.date = data.timestamp.toDate().toLocaleString('en-GB') //.toLocaleDateString('en-GB')
        data.title = data.title == null ? 'Sin Título' : data.title
        // days from payment
        if(data.paymentTimestamp != null){
          const timeDifference = today - data.paymentTimestamp.toDate()
          data.daysFromPayment = Math.floor(timeDifference / (1000 * 60 * 60 * 24))
        } else{
          data.daysFromPayment = 9999
        }
        items.push(data)
      })
      context.commit('setProcesses', items)
    }
  },
  modules: {
  }
})
