import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import 'vuetify/dist/vuetify.min.css'
import en from 'vuetify/es5/locale/en'
import es from 'vuetify/es5/locale/es'

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { en, es },
    current: 'es',
  },
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#8ECAE6',
        background: '#F2F2F2',
        secondary: '#023047',
        accent: '#FFB703',
        error: '#FF5252',
        info: '#219EBC',
        success: '#4CAF50',
        warning: '#FB8500',
      }
    }
  }
});
