<template>
  <v-row justify="center">
    <v-col cols="8" sm="12" class="py-6">
      <v-card :color="color ? 'blue lighten-5 elevation-7' : 'elevation-2'" @click="toggle" class="rounded-xl py-0" hover >
        <v-card-text class="text-center black--text py-2">
          <div class="text-h6">{{ !$vuetify.breakpoint.mobile ? card.title_card :  card.title_card.split(' ')[0]}}</div>
            <div class="text-subtitle-1" v-if="!card.disabled">
              <div class="text-h4 font-weight-medium" :class="color ? 'secondary--text':'secondary--text'">Q{{ card.price_card + nSigners*card.price_card_per_person}}.00</div>
              <div v-if="informative && card.price_card_per_person != 0" class="text-caption">+Q{{ card.price_card_per_person }} por firmante</div>
              <div v-else class="text-caption">Precio Final</div>
            </div>
            <div v-else>
              <div class="text-h4 font-weight-medium" :class="color ? 'secondary--text':'secondary--text'">Junio</div>
              <div class="text-caption">Próximamente</div>
            </div>
          <!-- <div class="py-3">{{ description_card || ''}}</div> -->
        </v-card-text>
        <!-- <v-divider class="mx-4"></v-divider> -->
        <!-- <benefitsPrices :benefits_card="card.benefits_card" :color_status="color" :clickEvent="clickEvent"></benefitsPrices> -->
      </v-card>
    </v-col>
  </v-row>

</template>

<script>
import benefitsPrices from './benefitsPrices.vue'

export default {
  name: 'priceCard',
  components: {
    benefitsPrices
  },
  props: {
    card: {
      required: true,
      type: Object
    },
    color: {
      required: true,
      type: Boolean
    },
    clickEvent: {
      required: true,
      type: Function
    },
    nSigners: {
      required: true,
      type: Number
    },
    informative: {
      required: false,
      default: false,
    },
  },
  
  methods: {
    toggle() {
      if(!this.color && (!this.card.disabled || this.informative)){
        this.clickEvent()
        this.$emit('update:signatureType', this.card.key)
      }
    }
  }
}

</script>
