<template>
  <v-card  max-width="auto" class="elevation-0">
    <v-list disabled :color="color_status ? 'blue lighten-5' : ''" @click="clickEvent">
      <v-list-item v-for="item in benefits_card" :key="item.key" >
        <v-icon color="blue darken-4" class="mr-1">mdi-check</v-icon>
        <v-list-item-title class="text-left" v-text="item.text" style="white-space: normal; word-wrap: break-word;"></v-list-item-title>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
export default {
  name: 'benefitsPrices',
  props: {
    benefits_card: {
      required: true,
      type: Array
    },
    color_status: {
      required: true,
      type: Boolean
    },
    clickEvent: {
      required: true,
      type: Function
    }
  }
}
</script>
