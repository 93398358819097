import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Meta from 'vue-meta'
import {getCurrentUser} from '@/services/firebase'

Vue.use(VueRouter)
Vue.use(Meta)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/create',
    name: 'create',
    component: () => import('../views/CreateView.vue'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/create/:id',
    name: 'create_id',
    component: () => import('../views/CreateView.vue'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/success_recurrente',
    name: 'ThankYou',
    component: () => import('../views/ThankYou.vue'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/auth/action',
    name: 'Auth',
    component: () => import('@/views/Auth.vue'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/myContracts',
    name: 'MyContracts',
    component: () => import('@/views/MyContracts.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/myContracts/:id',
    name: 'MyContracts-item',
    component: () => import('@/views/MyContracts.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/terms',
    name: 'Terms',
    component: () => import('@/views/Terms.vue'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: () => import('@/views/Privacy.vue'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('@/views/Contact.vue'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/oneShotProcess/:id',
    name: 'oneShotProcess',
    component: () => import('@/views/oneShot/oneShotProcess.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/oneShotProcess/:id/signer',
    name: 'oneShotProcess-signers',
    component: () => import('@/views/oneShot/oneShotProcessSigners.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/oneShotProcess/uploadSign/:id',
    name: 'oneShotProcess-uploadSign',
    component: () => import('@/views/uploadSign'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/oneShotProcess/signDocument/:id',
    name: 'oneShotProcess-signDocument',
    component: () => import('@/views/signDocument'),
    props: true,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/signatureCompleted/:id',
    name: 'signature-completed',
    component: () => import('@/views/signatureCompleted'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '*',
    name: 'not-found',
    component: () => import('@/views/notFoundPage.vue'),
    meta: {
      requiresAuth: false
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  if(requiresAuth && !await getCurrentUser()){
    next({ name: 'home' })
    // next()
  }
  next()
})

export default router
