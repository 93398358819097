import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFunctions } from 'firebase/functions';
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";


// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCH-c8vq-sGF2AkoF8GtVyL-ko0Wh72xZo",
  authDomain: "arrendamientosgt-a4533.firebaseapp.com",
  projectId: "arrendamientosgt-a4533",
  storageBucket: "arrendamientosgt-a4533.appspot.com",
  messagingSenderId: "204898465179",
  appId: "1:204898465179:web:e6cd6f254fb845e5dddd3e",
  measurementId: "G-8WS33QF7BB"
}

// const firebaseConfig = {
//   apiKey: process.env.VUE_APP_FIRECONFIG_APIKEY,
//   authDomain: process.env.VUE_APP_FIRECONFIG_AUTHDOMAIN,
//   databaseURL: process.env.VUE_APP_FIRECONFIG_DBURL,
//   projectId: process.env.VUE_APP_FIRECONFIG_PROJECTID,
//   storageBucket: process.env.VUE_APP_FIRECONFIG_STORAGE,
//   messagingSenderId: process.env.VUE_APP_FIRECONFIG_MSGSENDERID,
//   appId: process.env.VUE_APP_FIRECONFIG_APPID,
//   measurementId: process.env.VUE_APP_FIRECONFIG_MEASUREMENTID
// }

const app = initializeApp(firebaseConfig);

// console.log(process.env.NODE_ENV)
// if(process.env.NODE_ENV != 'development'){
//   // 8A0079C5-B283-42D0-87F7-00C734413B32
//   // CDBB575E-DE8C-4F1C-B887-61C95C399B21
//   self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
// }
const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider('6LeI80EnAAAAAB9HgzkZ1lMm-UcnWPKT8Vt5W4_n'),

  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true
});
const auth = getAuth(app);
const functions = getFunctions(app);
const db = getFirestore(app);
const storage = getStorage(app);
const analytics = getAnalytics(app);

let getCurrentUser = () => {
  return new Promise((resolve, reject) => {
      const unsubscribe = onAuthStateChanged(auth, (user) => {
        unsubscribe()
        resolve(user)
      }, reject)
  })
}

export {
  app,
  getCurrentUser,
  auth,
  db,
  storage,
  functions,
  appCheck,
  analytics
}
