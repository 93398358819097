<template>
  <v-item-group :mandatory="informative">
    <v-container>
    <v-row class="rounded-xl" justify="center">
      <v-col v-for="card in dataCards" :key="card.key" cols="12" :md="vertical ? '12': '4'" class="rounded-xl py-0">
        <v-item v-slot="{ active, toggle }" class="rounded-xl">
          <priceCard :color="allowSelection ? active : false" :clickEvent="toggle" class="rounded-xl" :card="card" :nSigners="nSigners" @update:signatureType="updateSignatureType" :informative="informative"></priceCard>
        </v-item>
      </v-col>
    </v-row>
  </v-container>
  </v-item-group>
</template>

<script>
import priceCard from './priceCard.vue'

export default {
  name: 'priceGroup',
  components: {
    priceCard,
  },
  props: {
    allowSelection: {
      required: false,
      default: true,
      type: Boolean
    },
    vertical: {
      required: false,
      default: false,
      type: Boolean
    },
    informative: {
      required: false,
      default: false,
    },
    dataCards: {
      required: true,
      type: Array
    },
    nSigners: {
      required: true,
      type: Number
    }
  },
  data() {
    return {
      signatureType: this.null
    }
  },
  methods: {
    updateSignatureType(title) {
      this.signatureType = this.signatureType === title ? null : title;
      this.$emit('update:signatureType', this.signatureType)
    },
  },
  mounted() {
    if(this.informative){
      this.signatureType = this.dataCards[0].key
    }
  }
}
</script>
