export default {
    priceCardsInformation: [
        {
          key: 'simple',
          title_card: 'Simple',
          price_card: 50,
          price_card_per_person: 0,
          description_card: '',
          benefits: [
            {text:'Opción más económica', description: ''},
            {text:'Contrato totalmente válido ante la ley Guatemalteca', description: ''},
            {text:'Únicamente firman las partes', description: ''},
          ],
          moreInfo:[
            'Según lo regulado en el Código Civil, los contratos de arrendamiento de bienes inmuebles pueden celebrarse por medio de documento privado siempre y cuando el plazo no sea mayor a 3 años y que no se pague anticipadamente la renta por más de un año. El Código no establece que dichos contratos deban celebrarse con alguna formalidad específica para que sean válidos.',
            'El Código Procesal Civil y Mercantil reconoce que los contratos de arrendamiento pueden constar en un documento privado para comprobar la relación jurídica entre las partes y no requiere que los mismos deban contar con firmas legalizadas. El procedimiento aplicable a las diferencias que puedan surgir en virtud de un contrato de arrendamiento es el Juicio Sumario. Al Juicio Sumario no le aplican supletoriamente las normas relativas a los títulos ejecutivos regulados en los procesos de ejecución. Por lo tanto, no es requisito que los contratos de arrendamiento tengan legalización de firmas.'
          ],
          svgIcon: 'sign.svg'
        },
        {
          key: 'legalized',
          title_card: 'Legalizada',
          price_card: 60,
          price_card_per_person: 0,
          warning: {
            text: 'No incluye servicio de legalización, contáctanos para referirte',
          },
          description_card: '',
          benefits: [
            {text:'Incluye el texto necesario para que un notario legalice las firmas', description: ''},
            {text:'Contrato totalmente valido ante la ley Guatemalteca', description: ''},
            {text:'Firma ante al notario de tu preferencia', description: ''},
            
          ],
          moreInfo:[
            'Según lo regulado en el Código Civil, los contratos de arrendamiento de bienes inmuebles pueden celebrarse por medio de documento privado siempre y cuando el plazo no sea mayor a 3 años y que no se pague anticipadamente la renta por más de un año. El Código no establece que dichos contratos deban celebrarse con alguna formalidad específica para que sean válidos.',
          ],
          svgIcon: 'nSign.svg'
        },
        {
          key: 'oneShot',
          title_card: 'Electrónica Avanzada',
          price_card: 60,
          price_card_per_person: 40,
          description_card: '',
          benefits: [
            {text:'Firma desde cualquier lugar', description: ''},
            {text:'Contrato totalmente válido ante la ley Guatemalteca', description: ''},
            {text:'Misma validez que firmar tradicionalmente', description: ''},
          ],
          moreInfo:[
            'Según lo regulado en el Código Civil, los contratos de arrendamiento de bienes inmuebles pueden celebrarse por medio de documento privado siempre y cuando el plazo no sea mayor a 3 años y que no se pague anticipadamente la renta por más de un año. El Código no establece que dichos contratos deban celebrarse con alguna formalidad específica para que sean válidos.',
          ],
          svgIcon: 'eSign.svg',
          disabled: false,
          disabledText: 'Disponible en Junio 2024'
        },
      ],
}
