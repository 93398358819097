<template>
  <div>
    
    <section>
      
      <div style="position:relative" class="wave-background py-0" :style="'height: calc(70vh - ' + $vuetify.application.top + 'px)'">
        <v-row align="center" justify="center" class="fill-height px-0">
          <v-scroll-y-reverse-transition appear>
            <v-col class="text-center px-0" cols="12" align-self="center">
              <h1 class="text-h4 font-weight-bold mb-4">
                Crea contratos de arrendamiento 
                <br>
                por solo Q50.00
              </h1>
              <h4 class="subheading pb-9">
                Listo para firmar en minutos
              </h4>
              <v-btn rounded depressed x-large color="secondary" raised @click="logEvent('cta_click', {name: 'main'});$router.push({name: 'create'}); ">Crea tu contrato</v-btn>
            </v-col>
          </v-scroll-y-reverse-transition>
        </v-row>
      </div>
      
    </section>
    <section id="steps" >
      <v-sheet class="pt-6" color="secondary" :style="'min-height: calc(30vh + 24px - 100px)'">
        <v-container class="text-center">
          <v-row>
            <v-col>
              <v-fade-transition appear>
                <div class="text-h4 white--text">En tres simples pasos</div>
              </v-fade-transition>
            </v-col>
          </v-row>
          <v-row justify="center" align="center" class="pt-4 white--text" fill-height>
            <v-fade-transition appear>
            <v-col cols="12" md="4">
                <v-img src="@/assets/Icono_llenar_formulario.svg" width="100" class="pb-3 mx-auto" color="white" contain></v-img>
                <div class="text-h5 font-weight-medium">1. Completa el formulario</div>
            </v-col>
            </v-fade-transition>
            <v-fade-transition appear>
            <v-col cols="12" md="4">
              <v-img src="@/assets/Icono_descargar_documento.svg" width="100" class="pb-3 mx-auto" color="white" contain></v-img>
              <div class="text-h5 font-weight-medium">2. Descarga el contrato </div>
            </v-col>
            </v-fade-transition>
            <v-fade-transition appear>
            <v-col cols="12" md="4">
              <v-img src="@/assets/Icono_firmar_contrato.svg" width="100" class="pb-3 mx-auto" color="white" contain></v-img>
              <div class="text-h5 font-weight-medium">3. Firma con las partes</div>
            </v-col>
            </v-fade-transition>
          </v-row>
        </v-container>
      </v-sheet>
    </section>
    <section>
      <v-sheet class="" color="" >
        <div class="wave-background2">
          <v-container class="text-center" style="padding-top: 100px;">
            <v-row align="center" justify="center" fill-height class="pt-10">
              <v-col class="text-left" cols="8" md="5" lg="5" xl="4">
                <v-row>
                  <v-col>
                    <div class="text-h4 secondary--text">Contratos de arrendamiento</div>
                  </v-col>
                </v-row>
                <Transition name="fade">
                <v-row>
                  <v-col class="text-h6 font-weight-light">
                    <v-row class="" align="top"  no-gutters>
                      <v-col cols="auto" class="class">
                        <v-icon color="primary">mdi-check</v-icon>
                      </v-col>
                      <v-col class="pl-1">
                        Válidos en Guatemala
                      </v-col>
                    </v-row>
                    <v-row class="" align="top"  no-gutters>
                      <v-col cols="auto" class="class">
                        <v-icon color="primary">mdi-check</v-icon>
                      </v-col>
                      <v-col class="pl-1">
                        Que protegen tus intereses
                      </v-col>
                    </v-row>
                    <v-row class="" align="top" no-gutters>
                      <v-col cols="auto" class="">
                        <v-icon color="primary">mdi-check</v-icon>
                      </v-col>
                      <v-col class="pl-1">
                        Personalizados a tus necesidades
                      </v-col>
                    </v-row>
                    <v-row class="" align="top" no-gutters>
                      <v-col cols="auto" class="">
                        <v-icon color="primary">mdi-check</v-icon>
                      </v-col>
                      <v-col class="pl-1">
                        Diseñados y validados por profesionales
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </Transition>
              </v-col>
              <v-col cols="10" sm="5" md="4" class="text-center">
                <v-card class="rounded-xl" flat>
                  <v-img src="@/assets/foto_smiling2.jpeg" max-height="270"></v-img>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-sheet>
    </section>

    <section>
      <v-sheet class="" color="background">
        <div class="wave-background3">
          <v-container class="text-center" style="padding-top: 100px;">
            <!-- <v-row>
              <v-col>
                <div class="text-center text-h4  secondary--text pt-6">Pensado para</div>
              </v-col>
            </v-row> -->
            <v-row class="pt-9" justify="center">
              <v-col cols="10" md="4">
                <v-card class="rounded-xl">
                  <v-img src="@/assets/propietarios.jpeg" :aspect-ratio="!$vuetify.breakpoint.mobile ? 11/16 : 16/9" :max-height="!$vuetify.breakpoint.mobile ? 300 : 200">
                    <div class="fill-height bottom-gradient">
                      <v-container class="fill-height">
                        <v-row align="end" class="fill-height py-0" no-gutters>
                          <v-col class="py-0">
                            <div class="text-h6 white--text">Propietarios</div>
                          </v-col>
                        </v-row>
                      </v-container>
                    </div>
                  </v-img>
                </v-card>
              </v-col>
              <v-col cols="10" md="4">
                <v-card class="rounded-xl">
                  <v-img src="@/assets/corredores.jpeg" :aspect-ratio="!$vuetify.breakpoint.mobile ? 11/16 : 16/9" :max-height="!$vuetify.breakpoint.mobile ? 300 : 200">
                    <div class="fill-height bottom-gradient">
                      <v-container class="fill-height">
                        <v-row align="end" class="fill-height py-0" no-gutters>
                          <v-col class="py-0">
                            <div class="text-h6 white--text">Corredores de bienes raices</div>
                          </v-col>
                        </v-row>
                      </v-container>
                    </div>
                  </v-img>
                </v-card>
              </v-col>
              <v-col cols="10" md="4">
                <v-card class="rounded-xl">
                  <v-img src="@/assets/empresas.jpeg" :aspect-ratio="!$vuetify.breakpoint.mobile ? 11/16 : 16/9" :max-height="!$vuetify.breakpoint.mobile ? 300 : 200">
                    <div class="fill-height bottom-gradient">
                      <v-container class="fill-height">
                        <v-row align="end" class="fill-height py-0" no-gutters>
                          <v-col class="py-0">
                            <div class="text-h6 white--text">Empresas</div>
                          </v-col>
                        </v-row>
                      </v-container>
                    </div>
                  </v-img>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-sheet>
    </section>
    <section >
      <v-sheet class="" color="">
        <div class="wave-background4">
          <v-container class="text-center fill-height" style="padding-top: 100px;">
            <v-row>
              <v-col>
                <div class="text-center text-h4 font-weight-bold secondary--text pt-6">Contratos que se adaptan al tipo de inmueble</div>
              </v-col>
            </v-row>
            <!-- <v-row>
              <v-col>
                <div class="text-body-1">Con ArrendamientosGT puedes generar contratos que se adaptan al tipo de inmueble</div>
              </v-col>
            </v-row> -->
            <v-row justify="center" align="center">
              <v-col cols="6" md="3" lg="2">
                <v-img src="@/assets/Icono_apartamento.svg" max-width="120" class="pb-0 mx-auto" color="white" contain></v-img>
                <div class="text-h5">Apartamento</div>
              </v-col>
              <v-col cols="6" md="3" lg="2">
                <v-img src="@/assets/Icono_bodega.svg" width="120" class="pb-0 mx-auto" color="white" contain></v-img>
                <div class="text-h5">Bodega</div>
              </v-col>
              <v-col cols="6" md="3" lg="2">
                <v-img src="@/assets/Icono_casa.svg" width="120" class="pb-0  mx-auto" color="white" contain></v-img>
                <div class="text-h5">Casa</div>
              </v-col>
              <v-col cols="6" md="3" lg="2">
                <v-img src="@/assets/Icono_oficina.svg" color="#023047" width="120" class="pb-0 mx-auto" contain></v-img>
                <div class="text-h5">Oficina</div>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div class="text-body-1">¿No encuentras tu tipo de inmueble? Déjanos un mensaje</div>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-sheet>
    </section>
    <section name="tiposDeFirma" id="pricing">
      <v-sheet class="" color="background">
        <div class="wave-background3">
          <v-container class="text-center" style="padding-top: 100px;">
            <v-row justify="center" class="text-center pt-6" >
              <v-col>
                <div class="text-center text-h4 font-weight-bold secondary--text">Precios y tipos de firma</div>
                <div class="text-h6">Selecciona un tipo de firma</div>
              </v-col>
            </v-row>
            <v-row align="center" justify="center" class="my-0 fill-height" fill-height>
              <v-col cols="12" md="4" lg="3" xl="2">
                <priceGroup :dataCards="priceCards" :nSigners="0" :vertical="true" :informative="true" @update:signatureType="updateSignatureType"></priceGroup>
              </v-col>
              <v-col cols="12" sm="12" md="8" lg="5" xl="4" class=" fill-height">
                <v-expand-transition>
                  <v-card class="rounded-xl text-left" :key="card.key">
                    <v-card-text class="black--text">
                      <v-row align="center" jusfify="space-between">
                        <v-col>
                          <div class="text-h5 font-weight-bold pb-3">{{ card.title_card }}</div>
                          <div class="pb-3 text-h6" v-if="!card.disabled">Precio:  Q{{ card.price_card}}.00 <span v-if="card.price_card_per_person != 0">+ Q{{ card.price_card_per_person }}.00 por firmante</span></div>
                          <div class="pb-3 text-h6" v-else>{{ card.disabledText }}</div>
                          <v-row v-for="benefit of card.benefits" :key="benefit.text" class="" align="top" justify="start" no-gutters>
                            <v-col cols="auto" class="">
                              <v-icon color="primary">mdi-check</v-icon>
                            </v-col>
                            <v-col class="pl-1">
                              {{ benefit.text }}
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col cols="12" md="3" v-if="$vuetify.breakpoint.mdAndUp">
                          <v-img contain max-height="150" src="@/assets/Icono_firmar_contrato_blue.svg"></v-img>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="py-0">
                          <div class="text-caption" v-if="card.warning != null">{{ card.warning.text }}</div>
                        </v-col>
                      </v-row>
                      <div class="pt-6"></div>
                    </v-card-text>
                  </v-card>
                </v-expand-transition>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-sheet>
    </section>
    <section name="faq" id="faq">
      <v-sheet class="" color="">
        <div class="wave-background4">
          <v-container class="text-center fill-height" style="padding-top: 100px;">
            <v-row justify="center" class="text-center pt-6">
              <v-col>
                <div class="text-center text-h4 font-weight-bold secondary--text">Preguntas frecuentes</div>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col cols="12" md="8" lg="7">
                <v-list class="gray--text" color="white">
                  <v-list-group v-for="item in faqItems" :key="item.title" v-model="item.active" no-action color="secondary">
                    <template v-slot:activator>
                      <v-list-item-content>
                        <v-list-item-title class="text-wrap text-left" v-text="item.title"></v-list-item-title>
                      </v-list-item-content>
                    </template>
                    <v-list-item v-for="child in item.items" :key="child.title" color="secondary">
                      <v-list-item-content class="text-left" color="secondary">
                        <div class="text-body-1">{{child.title}}</div>
                        <a class="text-body-1" v-if="child.link != null && child.link.internal" @click="$router.push({name: 'Contact'})">{{child.link.text}}</a>
                        <a class="text-body-1" v-else-if="child.link != null && !child.link.internal" :to="child.link.url">{{child.link.text}}</a>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-group>
                </v-list>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-sheet>
    </section>
    <section>
      <v-sheet class="" color="white">
        <v-row justify="center" class="pb-6">
          <v-col cols="11" md="7" lg="6">
            <v-card dark flat color="primary" class="my-8 rounded-xxl">
              <v-card-text class="white--text">
                <v-row align="center" justify="center">
                  <v-col cols="auto">
                    <div class="text-h6">Genera tu contrato en minutos</div>
                  </v-col>
                  <v-col cols="auto">
                    <v-btn rounded large color="secondary" raised @click="logEvent('cta_click', {name: 'banner_in_minutes'});$router.push({name: 'create'})">Inicia ahora</v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-sheet>
    </section>
  </div>
</template>

<script>
import { getAnalytics, logEvent } from "firebase/analytics";
import priceGroup from "@/components/prices/priceGroup.vue"
import cardInformation from "@/components/prices/cardInformation.js"

const analytics = getAnalytics();


  export default {
    name: 'Home',

    components: {
      priceGroup
    },
    computed: {
      card() {
        let card =  this.priceCards.find(item => {
          return item.key == this.signatureType
        })
        // card.svgIconData = import(card.svgIcon)
        return card
      },
      
    },
    data() {
      return {
        priceCards: cardInformation.priceCardsInformation,
        signatureType: cardInformation.priceCardsInformation[0].key,
        faqItems: [
          // {
          //   action: 'mdi-ticket',
          //   items: [{ title: 'Es un contrato por medio del cual una persona (Parte Arrendante), le otorga el uso de un inmueble a otra persona (Parte Arrendataria) por un periodo determinado (Plazo del Contrato), a cambio del pago de la renta.' },
          //           ],
          //   title: '¿Qué es un contrato de arrendamiento de inmueble?',
          // },
          {
            action: 'mdi-school',
            items: [{ title: 'Con ArrendamientosGT, puedes tener un contrato listo para firma en menos de 5 minutos.' }],
            title: '¿Cuánto tiempo toma crear un contrato?',
          },
          {
            action: 'mdi-school',
            items: [{ title: 'En el contrato de arrendamiento puedes establecer los datos del inmueble, plazo del contrato, la renta, destino del inmueble, las obligaciones de las partes, la garantía y otras condiciones importantes. A través de ArrendamientosGT puedes crear contratos personalizados que se adapten a tu caso particular.' }],
            title: '¿Qué opciones puedo personalizar en el contrato que se genere?',
          },
          {
            action: 'mdi-school',
            items: [{ title: 'Los contrato son redactados por profesionales en el área legal inmobiliaria, de acuerdo a las leyes vigentes en Guatemala.' }],
            title: '¿Cómo sé si los contratos generados son válidos legalmente?',
          },
          {
            action: 'mdi-school',
            items: [{ title: 'Sí, puedes ingresar a tu cuenta con tu correo y modificar tu contrato por 5 días. Si los 5 días pasaron, tendrás que pagar de nuevo.' }],
            title: '¿Puedo modificar un contrato después de haberlo generado?',
          },
          {
            action: 'mdi-school',
            items: [{ title: 'Los contratos de arrendamiento son válidos si se celebran sin firma legalizada. La legislación guatemalteca no lo establece como un requisito para su validez, ni como requisito para poder ser utilizado en un proceso judicial.' }],
            title: '¿Es válido un contrato sin firma legalizada?',
          },
          // {
          //   action: 'mdi-school',
          //   items: [{ title: 'En los contratos de arrendamiento la garantía fiduciaria es una figura por medio de la cual la Parte Fiadora (fiador)  garantiza el cumplimiento de las obligaciones de la Parte Arrendataria (inquilino) con sus propios bienes e ingresos. No es un requisito para la validez de un contrato de arrendamiento, sin embargo beneficia a la Parte Arrendante en caso sea necesario cobrar judicialmente las rentas, servicios o cualquier otra obligación incumplida por el arrendatario.' }],
          //   title: '¿Qué es un fiador? ¿Es necesario?',
          // },
          // {
          //   action: 'mdi-school',
          //   items: [{ title: 'La legalización de firmas no es un requisito para la validez, ni ejecución de un contrato de arrendamiento. La legalización de firmas tiene como ventaja el hecho que en un proceso judicial, la ley le reconoce mayor valor probatorio que un contrato sin firma legalizada.' }],
          //   title: '¿Cuáles son las ventajas de legalizar las firmas?',
          // },
          // {
          //   action: 'mdi-school',
          //   items: [{ title: 'El contrato de arrendamiento puede constar en documento privado si el plazo no es mayor a 3 años. Únicamente puede dar en arrendamiento un inmueble, el propietario y/o la persona que legalmente está facultada para darlo en arrendamiento.' }],
          //   title: '¿Qué requisitos se deben cumplir para que un contrato sea válido?',
          // },
          {
            action: 'mdi-school',
            items: [{link: {url:'Contact', text: 'Página de contacto', internal: true}, title: 'Puedes hacer click en el siguiente enlace para que un profesional te contacte y te apoye con elaborar el contrato de arrendamiento que necesitas.' }],
            title: '¿Qué puedo hacer si necesito un contrato más personalizado?',
          },
        ],
      }
    },
    methods: {
      // getImage(path){
      //   return require(path)
      // },
      updateSignatureType(title) {
        this.signatureType = title;
      },
      logEvent(name, attributes={}) {
        logEvent(analytics, name, attributes);
      }
    },
  }
</script>

<style scoped>
  .invisible {
    visibility: hidden;
  }
  .gradient-background {
    background: linear-gradient(180deg,#8ECAE6,#023047);
    background-size: 120% 120%;
    animation: gradient-animation 6s ease infinite;
  }
  .gradient-linear-background{
    background: rgb(142,202,230);
    background: linear-gradient(180deg, rgba(142,202,230,1) 0%, rgb(125, 194, 225) 20%, rgb(2, 59, 87) 95%, rgba(2,48,71,1) 100%);
    /* background: linear-gradient(180deg, rgba(142,202,230,1) 0%, rgba(2,48,71,1) 100%); */
  }
  .with-blur-backdrop {

    background-color: rgba(255, 255, 255, 0.4);

    -webkit-backdrop-filter: blur(5px);

    backdrop-filter: blur(5px);

    padding: 20px 30px;

  }
  .wave-background{
    /* background-image: url( '@/assets/wave.svg' );
    background-size: 100% 30%; */
    background-image: url( '@/assets/wave.svg' ), url( '@/assets/banner5tone.png' );
    background-size: 100% 30%, cover;
    background-position: bottom;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
  }.wave-background2{
    background-image: url( '@/assets/wave3.svg' );
    background-size: 100% 100px;
    background-position: top;
  }
  .wave-background3{
    background-image: url( '@/assets/wave4.svg' );
    background-size: 100% 100px;
    background-position: top;
  }
  .wave-background4{
    background-image: url( '@/assets/wave5.svg' );
    background-size: 100% 100px;
    background-position: top;
  }

  @keyframes gradient-animation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  .shadowText{
    text-shadow: 1px 1px 2px rgb(255, 255, 255), 0 0 1px rgb(255, 255, 255), 0 0 0.2px rgb(255, 255, 255);
  }

  .bottom-gradient {
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.9) 0%, transparent 90px);
  }

  .bottom-text{
    bottom: 0;
  }

  .fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
