<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
      elevate-on-scroll
    >
      <v-avatar size="80" :tile="true" class="mr-3" @click="$router.push({name:'home'})" style="cursor: pointer;" hover>
        <v-img src="@/assets/logo.png" alt="logo" contain></v-img>
      </v-avatar>
      <v-row align="center">
        <!-- <v-col cols="1" class="pr-0">
            <v-img src="@/assets/logo.png" :style="'max-height: calc(-10 + 0.60*' + $vuetify.application.top + 'px)'"></v-img>
        </v-col> -->
        <v-col cols="auto" class="" v-if="!$vuetify.breakpoint.mobile" hover>
          <div class="text-h5 font-weight-bold"  @click="$router.push({name:'home'})" style="cursor: pointer;">{{ appTitle }}</div>
        </v-col>
      </v-row>
      <!-- {{ loggedIn }} -->
      <v-spacer></v-spacer>
      <!-- <v-btn text class="mx-2">Asesórate con expertos</v-btn> -->
      <v-btn v-if="['home'].includes($route.name) && !$vuetify.breakpoint.mobile" text :large="!$vuetify.breakpoint.mobile" @click="$vuetify.goTo('#pricing');">Precios</v-btn>
      

      <v-btn v-if="['home', 'Terms', 'Privacy', 'create_id', 'create'].includes($route.name) && !$vuetify.breakpoint.mobile" text :large="!$vuetify.breakpoint.mobile" @click="$router.push({name: 'Contact'})">Contacto</v-btn>
      
      <v-btn v-if="['home', 'Terms', 'Privacy', 'MyContracts', 'MyContracts-item', 'Contact'].includes($route.name)" rounded :large="!$vuetify.breakpoint.mobile" class="ml-0 mr-2" @click="logEvent('cta_click', {name: 'app_bar'});$router.push({name: 'create'})" depressed color="secondary">
        <span v-if="!$vuetify.breakpoint.mobile">Crea tu contrato</span>
        <span v-else>Crear</span>
      </v-btn>

      <v-btn v-if="['home'].includes($route.name) && $vuetify.breakpoint.mobile" icon :large="!$vuetify.breakpoint.mobile" @click="$vuetify.goTo('#pricing');">
        <v-icon>mdi-currency-usd</v-icon>
      </v-btn>
      <v-btn v-if="['home', 'Terms', 'Privacy', 'create_id', 'create'].includes($route.name) && $vuetify.breakpoint.mobile" icon :large="!$vuetify.breakpoint.mobile" @click="$router.push({name: 'Contact'})">
        <v-icon>mdi-chat-outline</v-icon>
      </v-btn>
      
      
      <v-btn icon @click="loggedIn ? $router.push({name: 'MyContracts'}) : $router.push({name: 'Login'})">
        <v-icon>mdi-account</v-icon>
      </v-btn>
      <!-- <v-btn icon v-else @click="$router.push({name: 'Login'})">
        <v-icon>mdi-account</v-icon>
      </v-btn> -->
      <v-menu offset-y v-if="loggedIn">
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            icon
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            @click="logout"
          >
            <v-list-item-title>Cerrar Sesión</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      
    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>

    <v-footer app absolute class="back pt-20">
      <v-col
        class="text-center"
        cols="12"
      >
        <div class="text-caption">ArrendamientosGT provee acceso a herramientas de generación de documentos. No somos una firma de abogados y no ofrecemos asesoría legal.</div>
        <v-row justify="center" class="pt-10 pb-5">
          <v-col cols="auto">
            <a class="text-body-2" text to="Terms" @click="$router.push({name: 'Terms'})">Términos y condiciones</a>
          </v-col>
          <v-divider vertical></v-divider>
          <v-col cols="auto">
            <a class="text-body-2" text to="Privacy" @click="$router.push({name: 'Privacy'})">Política de privacidad</a>
          </v-col>
        </v-row>
        {{ new Date().getFullYear() }} — <strong>ArrendamientosGT</strong>
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
import {auth} from '@/services/firebase'
import { signOut } from "firebase/auth";

import { onAuthStateChanged } from "firebase/auth";
import { getAnalytics, logEvent } from "firebase/analytics";

const analytics = getAnalytics();


export default {
  name: 'App',
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'Inicio',
    // all titles will be injected into this template
    titleTemplate: '%s | ArrendamientosGT',
    meta: [
      { charset: 'utf-8' },
      { name: 'description', content: 'Crea contratos de arrendamiento listos para firmar en menos de 5 minutos. Redactados por profesionales en el área legal inmobiliaria, de acuerdo a las leyes vigentes en Guatemala. Puedes personalizar tu contrato definiendo los datos del inmueble, plazo del contrato, la renta, destino del inmueble, las obligaciones de las partes, la garantía y otras condiciones importantes.' },
    ]
  },
  data: () => ({
    //
    loggedIn: false
  }),

  computed: {
    appTitle() {
      return process.env.VUE_APP_TITLE
    }
  },
  methods: {
    logEvent(name, attributes={}) {
        logEvent(analytics, name, attributes);
    },
    checkAuth () {
      let vm = this
      onAuthStateChanged(auth, (user) => {
        if (user) {
          // console.log(user)
          vm.loggedIn = true
          vm.$store.state.uid = user.uid
        }
        else {
          vm.loggedIn = false
          vm.$store.state.uid = null
        }
      })
    },
    logout () {
      signOut(auth).then(() => {
        this.$router.push({name: 'home'})
      })
    },
  },
  beforeMount(){
    this.loggedIn = false
    this.checkAuth()
  }
};
</script>

<style scoped>
  .back {
   z-index: 0;
  }
</style>

